import { useState } from "react";
import { BsClipboardCheck } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div
      className="bg-gray-50 dark:bg-dark-900 w-full min-h-screen flex items-center py-16 md:py-24 transition-colors duration-200 font-roboto"
      id="contact"
    >
      <div className="w-full max-w-7xl mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="order-2 lg:order-1">
            <div 
              className="h-full bg-white dark:bg-dark-800 rounded-2xl shadow-xl overflow-hidden"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <FeedBackForm />
            </div>
          </div>
          <div className="order-1 lg:order-2">
            <div 
              className="h-full bg-sky-600 dark:bg-sky-950 rounded-2xl shadow-xl overflow-hidden"
              data-aos="fade-up"
            >
              <ContactInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactInfo = () => {
  const [emailCopied, setEmailCopied] = useState(false);
  const email = "hr@qualmission.com";

  const handleCopyEmail = async () => {
    try {
      await navigator.clipboard.writeText(email);
      setEmailCopied(true);
      setTimeout(() => setEmailCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy email:', err);
    }
  };

  return (
    <div className="h-full p-8 md:p-10 text-white">
      <div className="h-full flex flex-col space-y-10">
        <div className="space-y-8">
          <h2 className="text-3xl md:text-4xl font-bold text-white tracking-tight font-roboto">
            Get in touch
          </h2>
          <p className="text-lg text-gray-200 leading-relaxed font-roboto font-light">
            We are firm believers in performing ethical business operations and
            cultivating values-based relationships with clients, workers, and anyone
            else that joins us in the organization's growth.
          </p>
        </div>

        <div className="flex-grow space-y-8">
          <div>
            <p className="text-lg text-gray-200 leading-relaxed font-roboto font-light">
              We will help you to find the right best Fit. We have new openings
              everyday! E-mail your resume now.
            </p>
          </div>

          <div className="flex justify-between items-stretch gap-4">
            <div className="flex-1 p-4 bg-white/5 rounded-xl border border-white/10 hover:bg-white/10 transition-colors duration-200">
              <div className="space-y-2">
                <Link 
                  className="block text-xl text-sky-200 hover:text-white underline decoration-sky-400/30 hover:decoration-white/70 transition-all duration-200 font-roboto font-light" 
                  to={`mailto:${email}`}
                >
                  {email}
                </Link>
                <button
                  className={`flex items-center gap-2 text-base font-roboto font-light ${
                    emailCopied ? "text-lime-300" : "text-gray-300 hover:text-white"
                  } transition-colors duration-200`}
                  onClick={handleCopyEmail}
                  type="button"
                  aria-label={emailCopied ? "Email copied!" : "Copy email address"}
                >
                  <BsClipboardCheck className="text-lg" />
                  <span>{emailCopied ? "Copied!" : "Copy email"}</span>
                </button>
              </div>
            </div>

            <div className="flex-1 p-4 bg-white/5 rounded-xl border border-white/10 hover:bg-white/10 transition-colors duration-200">
              <Link
                to="https://www.linkedin.com/company/qualmission-llc"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-3 text-sky-200 hover:text-white group transition-colors duration-200 font-roboto font-light"
              >
                <div className="p-2.5 rounded-full bg-white/10 group-hover:bg-white/20 transition-colors duration-200">
                  <FaLinkedin className="w-5 h-5" />
                </div>
                <span className="text-lg">Follow us on LinkedIn</span>
              </Link>
            </div>
          </div>

          <div className="pt-6 border-t border-white/10">
            <div className="flex justify-center gap-8">
              <div className="flex items-center gap-2">
                <div className="p-2 rounded-lg bg-white/10">
                  <svg className="w-4 h-4 text-sky-200" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <span className="text-gray-300 font-roboto font-light">India</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="p-2 rounded-lg bg-white/10">
                  <svg className="w-4 h-4 text-sky-200" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <span className="text-gray-300 font-roboto font-light">USA</span>
              </div>
            </div>
            <div className="text-center space-y-2 mt-6">
              <p className="text-gray-300 font-roboto font-light italic">"Join our mission to innovate and create meaningful impact in the world of technology."</p>
              <p className="text-sky-200 font-roboto font-light">Your journey to excellence begins here.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedBackForm = () => {
  const [inputs, setInputs] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    // Handle form submission
    setTimeout(() => {
      setSubmitted(false);
      setInputs({});
    }, 3000);
  };

  return (
    <div className="h-full p-8 md:p-12 bg-white dark:bg-dark-800">
      <div className="h-full flex flex-col max-w-xl mx-auto">
        {!submitted ? (
          <>
            <div className="mb-10 space-y-4">
              <h2 className="text-3xl md:text-4xl font-bold text-sky-600 dark:text-sky-400 tracking-tight font-roboto">
                How about a call?
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300 font-roboto font-light">
                We'd love to hear from you. Please fill out this form.
              </p>
            </div>

            <form className="flex-grow flex flex-col space-y-8" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-roboto">
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="w-full px-4 py-3 bg-white dark:bg-dark-700 rounded-xl border border-gray-200 dark:border-transparent focus:outline-none focus:ring-2 focus:ring-sky-500/20 dark:focus:ring-sky-400/20 text-gray-900 dark:text-gray-100 placeholder-gray-400 shadow-sm transition-all duration-200 font-roboto"
                    required
                    value={inputs.name || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-roboto">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="w-full px-4 py-3 bg-white dark:bg-dark-700 rounded-xl border border-gray-200 dark:border-transparent focus:outline-none focus:ring-2 focus:ring-sky-500/20 dark:focus:ring-sky-400/20 text-gray-900 dark:text-gray-100 placeholder-gray-400 shadow-sm transition-all duration-200 font-roboto"
                    required
                    value={inputs.email || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-roboto">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    className="w-full px-4 py-3 bg-white dark:bg-dark-700 rounded-xl border border-gray-200 dark:border-transparent focus:outline-none focus:ring-2 focus:ring-sky-500/20 dark:focus:ring-sky-400/20 text-gray-900 dark:text-gray-100 placeholder-gray-400 shadow-sm transition-all duration-200 font-roboto"
                    required
                    value={inputs.phone || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-roboto">
                    Subject
                  </label>
                  <input
                    type="text"
                    name="subject"
                    className="w-full px-4 py-3 bg-white dark:bg-dark-700 rounded-xl border border-gray-200 dark:border-transparent focus:outline-none focus:ring-2 focus:ring-sky-500/20 dark:focus:ring-sky-400/20 text-gray-900 dark:text-gray-100 placeholder-gray-400 shadow-sm transition-all duration-200 font-roboto"
                    required
                    value={inputs.subject || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-roboto">
                  Your Message
                </label>
                <textarea
                  name="message"
                  rows="6"
                  className="w-full px-4 py-3 bg-white dark:bg-dark-700 rounded-xl border border-gray-200 dark:border-transparent focus:outline-none focus:ring-2 focus:ring-sky-500/20 dark:focus:ring-sky-400/20 text-gray-900 dark:text-gray-100 placeholder-gray-400 shadow-sm transition-all duration-200 resize-none font-roboto"
                  required
                  value={inputs.message || ""}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="pt-4">
                <button
                  type="submit"
                  className="w-full py-4 text-lg font-medium text-white bg-sky-600 hover:bg-sky-700 rounded-xl transition-all duration-300 ease-in-out transform hover:scale-[1.02] shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-sky-500/20 dark:focus:ring-sky-400/20 font-roboto"
                >
                  Send Message
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center flex-grow py-12 space-y-6">
            <div className="w-16 h-16 bg-sky-50 dark:bg-sky-900/30 rounded-full flex items-center justify-center">
              <svg
                className="w-8 h-8 text-sky-600 dark:text-sky-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div className="text-center space-y-3">
              <h3 className="text-2xl font-bold text-sky-600 dark:text-sky-400 font-roboto">
                Thank you for your message!
              </h3>
              <p className="text-lg text-gray-600 dark:text-gray-300 font-roboto font-light">
                We'll get back to you soon.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
