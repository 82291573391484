import NavBar from "./navbar";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import logo from "../logo_n3.png";
import Career from "./contactus";
import { useState, useEffect } from "react";

function Layout() {
  return (
    <div className='App w-full h-full bg-white dark:bg-dark-900 transition-colors duration-150'>
      <canvas
        className='background opacity-30 dark:opacity-20'
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      />
      <div className="relative z-10">
        <NavBar />
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='w-full pt-20 lg:pt-32 pb-12'>
            <Outlet />
            <Career />
          </div>
        </div>
        <Footer />
        <BackToTop />
      </div>
    </div>
  );
}

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <button
      className={`fixed bottom-8 right-8 rounded-full bg-sky-900 dark:bg-sky-700 p-4 text-lg text-sky-200 shadow-2xl transition-all duration-300 hover:bg-sky-800 dark:hover:bg-sky-600 z-50 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
      onClick={scrollToTop}
      aria-label="Scroll to top"
    >
      <FaArrowUp className="w-5 h-5" />
    </button>
  );
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export default Layout;
